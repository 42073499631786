import React, { useEffect, useState } from "react";
import "../../fonts/proxima-nova-regular.otf";
// nodejs library that concatenates classes
import classnames from "classnames";
import axios from "axios";
import io from "socket.io-client";
import swal from "sweetalert";
import InputMask from "react-input-mask";
import styles from "./Landing.module.css";
import logo from "../../assets/img/logo-inter.png";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const URL_API = "https://api.leads.interbrasilsaude.com.br/v1";

const types = {
  email: {
    regex:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: "Preencha um e-mail válido",
  },
  telefone: {
    regex:
      /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
    message: "Preencha um telefone",
  },
};

const Landing = () => {
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [telefone, setTelefone] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [contado, setContato] = useState("");
  const [uf, setUf] = useState("");
  const [entidade, setEntidade] = useState("");
  const [emailFocused, setEmailFocuded] = useState(false);
  const [nomeFocused, setNomeFocuded] = useState(false);
  const [quantidadeFocused, setQuantidadeFocuded] = useState(false);
  const [telefoneFocused, setTelefoneFocuded] = useState(false);
  const [contadoFocused, setContadoFocuded] = useState(false);
  const [entidadeFocused, setEntidadeFocuded] = useState(false);
  const [ufFocused, setUfFocuded] = useState(false);
  const [inputActived, setInputActived] = useState(false);

  const [error, setError] = React.useState(null);

  const [entidades, setEntidades] = useState([]);
  const [ufs, setUfs] = useState([]);
  const [entidadesSelecionadas, setEntidadesSelecionadas] = useState([]);

  const socket = io("https://api.leads.interbrasilsaude.com.br", {
    secure: true,
  });

  function validate(value, type) {
    if (type === false) return true;
    if (value.length === 0) {
      // setError("Preencha um valor.");
      return false;
    } else if (types[type] && !types[type].regex.test(value)) {
      setError(types[type].message);
      return false;
    } else {
      setError(null);
      return true;
    }
  }

  function getEntidades() {
    axios
      .get(`${URL_API}/entidades`)
      .then((res) => {
        setEntidades(res.data);
        console.log(res.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // this.refs.main.scrollTop = 0;
    getEntidades();
    socket.on("connect", () => {
      console.log(socket.id);
    });

    return () => () => socket.on("disconnect", () => {});
  }, []);

  useEffect(() => {
    const estados = entidades.map((entidade) => {
      return entidade.uf;
    });
    const estadosFilter = estados.filter((item, i) => {
      return estados.indexOf(item) === i;
    });

    setUfs(estadosFilter);
  }, [entidades]);

  useEffect(() => {
    if (uf == "null" || uf == "1") {
      setInputActived(false);
    }
    const entidadesSelecionadas = entidades.filter((i) => {
      return i.uf === uf;
    });
    setEntidadesSelecionadas(entidadesSelecionadas);
  }, [uf, entidades]);

  const newLead = () => {
    let error = "";
    if (!email) error += "- O email é obrigatório!\n";
    if (!nome) error += "- O nome é obrigatório!\n";
    if (!telefone) error += "- O telefone é obrigatório!\n";
    if (!quantidade) error += "- O quantidade é obrigatório!\n";
    if (!contado) error += "- O contato é obrigatório!\n";
    if (!uf) error += "- O estado é obrigatório!\n";
    if (!entidade) error += "- A entidade é obrigatório!\n";
    if (error) {
      swal("Falha ao salvar:", error);
      return;
    }

    socket.emit("newLead", {
      nome,
      email,
      telefone,
      pref_contato: contado,
      status: "disponivel",
      qdade_dependentes: quantidade,
      id_entidade: entidade,
    });
    let hello = "Bom dia!";
    const h = new Date().getHours();
    if (h > 12 && h <= 18) {
      hello = "Boa Tarde!";
    } else if (h > 18 || h < 6) {
      hello = "Boa Noite!";
    }
    swal(hello, "O seu lead foi enviado!", "success", {
      button: "Ok!",
    });
    setEmail("");
    setNome("");
    setTelefone("");
    setQuantidade("");
    setContato("");
    setEntidade("");
  };

  return (
    <>
      <main className={styles.landing}>
        <p className={styles.descricao}>
          Quando o assunto é <span>gestão de saúde</span>, seja sua, da sua
          empresa ou da sua entidade representativa, a{" "}
          <span>Interbrasil Saúde</span> é a escolha certa a se fazer.
        </p>
        <section className="section section-mb pt-lg-0 section-contact-us">
          <Row className="justify-content-center" style={{ width: "100%" }}>
            <Col lg="12">
              <Card
                className="bg-gradient-secondary shadow"
                style={{ borderRadius: "15px" }}
              >
                <CardBody className="p-lg-4">
                  <h4 className="mb-1">Solicite seu Plano de Saúde</h4>
                  <p className="mt-0">Preencha corretamente os campos abaixo</p>

                  <FormGroup
                    className={classnames("mt-2", {
                      focused: emailFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="E-mail"
                        type="email"
                        value={email}
                        onChange={(e) => {
                          if (error) validate(e.target.value, "email");
                          setEmail(String(e.target.value).trim().toLowerCase());
                        }}
                        onFocus={(e) => setEmailFocuded(true)}
                        onBlur={(e) => {
                          validate(email, "email");
                          setEmailFocuded(false);
                        }}
                      />
                    </InputGroup>
                    {error && (
                      <p
                        style={{
                          color: "#f31",
                          fontSize: "0.975rem",
                          marginTop: "0.25rem",
                        }}
                      >
                        {error}
                      </p>
                    )}
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: nomeFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Nome"
                        type="text"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                        onFocus={(e) => setNomeFocuded(true)}
                        onBlur={(e) => setNomeFocuded(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: telefoneFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-mobile-button" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="tel"
                        mask="(99) 999999999"
                        maskChar=" "
                        tag={InputMask}
                        placeholder="Telefone"
                        value={telefone}
                        onChange={(e) =>
                          setTelefone(String(e.target.value).trim())
                        }
                        onFocus={(e) => setTelefoneFocuded(true)}
                        onBlur={(e) => setTelefoneFocuded(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: quantidadeFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-bullet-list-67" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Quantidade de dependentes"
                        type="number"
                        value={quantidade}
                        onChange={(e) => {
                          const valor = parseInt(e.target.value);
                          if (valor >= 0) {
                            setQuantidade(valor);
                          }
                        }}
                        onFocus={(e) => setQuantidadeFocuded(true)}
                        onBlur={(e) => setQuantidadeFocuded(false)}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup
                    className={classnames({
                      focused: contadoFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-chat-round" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder=" Selecione a sua forma preferida de contato"
                        type="select"
                        value={contado}
                        onChange={(e) => setContato(e.target.value)}
                        onFocus={(e) => setContadoFocuded(true)}
                        onBlur={(e) => setContadoFocuded(false)}
                      >
                        <option value="null" defaultValue>
                          Selecione a sua forma preferida de contato
                        </option>
                        <option value="E-mail">E-mail</option>
                        <option value="Whatsapp">Whatsapp</option>
                        <option value="Telefone">Telefone</option>
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: ufFocused,
                    })}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-building" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Selecione um Estado"
                        type="select"
                        value={uf}
                        onChange={(e) => {
                          if (e.target.value == "1") {
                            setEntidade(parseInt(e.target.value));
                            setUf(e.target.value);
                          } else {
                            setUf(e.target.value);
                            setInputActived(true);
                          }
                        }}
                        onFocus={(e) => setUfFocuded(true)}
                        onBlur={(e) => setUfFocuded(false)}
                      >
                        <option value="null" defaultValue>
                          Selecione um Estado
                        </option>
                        <option value="1">Nenhum</option>
                        {ufs.map((item) =>
                          item !== null ? (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ) : (
                            ""
                          ),
                        )}
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <FormGroup
                    className={classnames({
                      focused: entidadeFocused,
                    })}
                  >
                    {inputActived && (
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-building" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Selecione a entidade"
                          type="select"
                          value={entidade}
                          onChange={(e) => {
                            const valor =
                              e.target.value == "null"
                                ? e.target.value
                                : parseInt(e.target.value);
                            setEntidade(valor);
                          }}
                          onFocus={(e) => setEntidadeFocuded(true)}
                          onBlur={(e) => setEntidadeFocuded(false)}
                        >
                          <option value="null" defaultValue>
                            Selecione uma Empresa ou Entidade
                          </option>
                          <option value="1">Nenhuma</option>
                          {entidadesSelecionadas.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.descricao}
                            </option>
                          ))}
                        </Input>
                      </InputGroup>
                    )}
                  </FormGroup>
                  <div>
                    <Button
                      block
                      className="btn-round"
                      color="default"
                      size="lg"
                      type="button"
                      onClick={() => newLead()}
                    >
                      SOLICITAR
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <img src={logo} alt="" />
        </section>
      </main>
    </>
  );
};

export default Landing;
